// import

import {parseEnvVars, getProcessEnv, getWindowEnv, hasWindowEnv} from '@cat/env';

// types

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      PORT?: `${number}`;

      CLERK_PUBLISHABLE_KEY?: string;
      CLERK_SECRET_KEY?: string;
      MAILERLITE_API_KEY?: string;
      MAILERSEND_API_KEY?: string;
      SENTRY_AUTH_TOKEN?: string;
      SENTRY_DSN?: string;
      SENTRY_ENVIRONMENT?: string;
      SENTRY_RELEASE?: string;
      STRIPE_PUBLIC_KEY?: string;
      STRIPE_SECRET_KEY?: string;
      SUPABASE_ACCESS_TOKEN?: string;
      SUPABASE_AUTH_KEY?: string;
    }
  }
}

// site

export {isClient, isServer} from '@cat/env';
export const env = parseEnvVars(hasWindowEnv ? getWindowEnv() : getProcessEnv());

export const siteName = 'BrowserCat';
